<template>
  <v-dialog :value="value" persistent>
    
    <v-card color="fill" v-resize="onResize" style='overflow:hidden'>
      <v-row>
        <v-col cols="11">
          <v-card-title :class="title">{{selectedAd.ad_name}} Ad Planner</v-card-title>
        </v-col>
        <v-spacer />
        <v-col dense class="mr-0">
          <v-btn
            @click="download"
            :loading="loadingFile"
            :disabled="loadingFile"
            class="mt-2"
            text
            icon>
            <v-icon>mdi-file-download-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col class='mr-0 pr-4' dense>
          <v-btn class="mt-2" text @click="$emit('update:value', false)" icon>
           <v-icon>mdi-close</v-icon>
          </v-btn>     
        </v-col>
      </v-row>
      <v-divider />
      <v-data-table 
      :headers="headers" 
      :height="tableHeight" 
      :items.sync="items" 
      class="mb-4"
      group-by='pagePos'
      no-data-text="No Data" 
      loading-text="Loading..."
      :loading="loading"
      style="cursor:pointer" 
      dense fixed-header hide-default-footer
      :items-per-page="pageSize"
      :expanded="expanded">

      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
        <td :colspan="headers.length">
                <v-btn dense @click="toggle" small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
                {{items[0].page_name + " : " + items[0].position_name }} 
            </td>
        </template>

       <!-- formatting the headers -->
        <template #[`item.case_cost`]="{ item }">
          <span v-if="item.case_cost">${{formatCurrencyDisplay(item.case_cost)}}</span>
          <span v-else> - </span>
        </template>

        <template #[`item.amap`]="{ item }">
          <span v-if="item.amap">  ${{formatCurrencyDisplay(item.amap)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.early_buy_billback`]="{ item }">
          <span v-if="item.early_buy_billback">  ${{formatCurrencyDisplay(item.early_buy_billback)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.ad_billback`]="{ item }">
        <span v-if="item.ad_billback">  
          ${{formatCurrencyDisplay(item.ad_billback)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.edlc_billback`]="{ item }">
        <span v-if="item.edlc_billback">  
          ${{formatCurrencyDisplay(item.edlc_billback)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.tpr_billback`]="{ item }">
          <span v-if="item.tpr_billback">  ${{formatCurrencyDisplay(item.tpr_billback)}} </span>
          <span v-else> - </span>
        </template>
        
        <template #[`item.dsd_oi_billback`]="{ item }">
          <span v-if="item.dsd_oi_billback">  ${{formatCurrencyDisplay(item.dsd_oi_billback)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.ad_scan`]="{ item }">
          <span v-if="item.ad_scan">  ${{formatCurrencyDisplay(item.ad_scan)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.edlc_scan`]="{ item }">
          <span v-if="item.edlc_scan">  ${{formatCurrencyDisplay(item.edlc_scan)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.complex_scan`]="{ item }">
           <span v-if="item.complex_scan">  ${{formatCurrencyDisplay(item.complex_scan)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.tpr_scan`]="{ item }">
           <span v-if="item.tpr_scan">  ${{formatCurrencyDisplay(item.tpr_scan)}} </span>
          <span v-else> - </span>
        </template>
        
        <template #[`item.epay_edlc_scan`]="{ item }">
           <span v-if="item.epay_edlc_scan">  ${{formatCurrencyDisplay(item.epay_edlc_scan)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.epay_tpr_scan`]="{ item }">
           <span v-if="item.epay_tpr_scan">  ${{formatCurrencyDisplay(item.epay_tpr_scan)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.epay_ad_scan`]="{ item }">
           <span v-if="item.epay_ad_scan">  ${{formatCurrencyDisplay(item.epay_ad_scan)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.edlc_scan_compete`]="{ item }">
           <span v-if="item.edlc_scan_compete">  ${{formatCurrencyDisplay(item.edlc_scan_compete)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.tpr_scan_compete`]="{ item }">
           <span v-if="item.tpr_scan_compete">  ${{formatCurrencyDisplay(item.tpr_scan_compete)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.regular_retail`]="{ item }">
          <span v-if="item.regular_retail">  ${{formatCurrencyDisplay(item.regular_retail)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.unit_cost`]="{ item }">
          <span v-if="item.unit_cost">  ${{formatCurrencyDisplay(item.net_unit_cost)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.complex_net_unit_cost`]="{ item }">
           <span v-if="item.complex_net_unit_cost">  ${{formatCurrencyDisplay(item.complex_net_unit_cost)}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.ad_retail`]="{ item }">
          <span v-if="item.ad_retail">  ${{formatCurrencyDisplay(item.ad_retail)}} </span>
          <span v-else> - </span>
        </template>
        
        <template #[`item.qty_per_case`]="{ item }">
            <span v-if="item.qty_per_case">  {{item.qty_per_case}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.last_90_days_shipped_qty`]="{ item }">
            <span v-if="item.last_90_days_shipped_qty">  {{item.last_90_days_shipped_qty}} </span>
          <span v-else> - </span>
        </template>

        <template #[`item.ad_gross_profit_percent`]="{ item }">
            <span v-if="item.ad_gross_profit_percent">  {{formatCurrencyDisplay(item.ad_gross_profit_percent)}}% </span>
          <span v-else> - </span>
        </template>

        <template #[`item.components`]="{ item }">
        <v-tooltip top v-if="item.is_shipper">
          <template #activator="{ on }">
            <v-btn v-on="on"
              icon color="primary" @click="selectedItem=item, showComponents = true">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>Components</span>
        </v-tooltip>
      </template>

      </v-data-table>
      <v-toolbar dense flat color="#fafafa" class='mt-0'>
      <v-row align="center" justify="end" class='mt-0 pt-0'>
        <v-col cols="2" style="max-width:150px;">
          <v-select class="mt-5" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
            :menu-props="{ top: true, offsetY: true, maxHeight: 500 }" @change='getItems()'>
          </v-select>
        </v-col>
        <v-btn icon dense @click="previous()" :disabled="disablePrevious" class="mx-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <small>Page {{page}}</small>
        <v-btn icon dense @click="next()" :disabled="disableNext" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>

    <ComponentItems :value.sync="showComponents" :selectedItem="selectedItem" :selectedAd="selectedAd" />
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { format } from '@/mixins/format'

const ComponentItems = () => import('@/components/ads/ComponentItems.vue')

export default {
  name: 'AdPlannerView',
  mixins: [displayHelpers, format],
  components: { ComponentItems },
  props: [
    'value',
    'selectedAd'
  ],
  data () {
    return {
    items: [],
    search: '',
    tableHeight:'',
    expanded: [],
    sortBy:'page_name',
    sortDesc:true,
    offset: true,
    showComponents: false,
    selectedItem: {},
    page: 1,
    pageSize: 50,
    pageSizes: [50, 100],
    loading: false,
    isDocumentThere: false,
    loadingFile: false,
    doc: {},
    link: {}
    }
  },
  computed: {
    disableNext () {
      return this.items.length < this.pageSize
    },
    disablePrevious () {
      return (this.page - 1) * this.pageSize === 0
    },
    headers () {
      if (this.$auth.tenant === 'pricechopper') {
          return [
          { sortable: false, class: 'accent white--text', width: '3%', show: true},
          { text: 'PCE Link Code', align: 'left', sortable: false, filterable: true, value: 'pce_link_code', class: 'accent white--text', width: '4%'},
          { text: 'Dept', align: 'left', sortable: false, filterable: true, value: 'department', class: 'accent white--text', width: '10%' },
          { text: 'Feature Id', align: 'left', sortable: false, filterable: true, value: 'feature_id', class: 'accent white--text', width: '10%' },
          { text: 'Feature Desc', align: 'left', sortable: false, filterable: true, value: 'feature_text', class: 'accent white--text' },
          { text: 'Item Code', align: 'left', sortable: false, filterable: true, value: 'item_id', class: 'accent white--text', width: '6%'},
          { text: 'GTIN', align: 'left', sortable: false, filterable: true, value: 'gtin', class: 'accent white--text', width: '2%'},
          { text: 'UPC', align: 'left', sortable: false, filterable: true, value: 'upc', class: 'accent white--text', width: '2%'},
          { text: 'Item Desc', align: 'left', sortable: false, filterable: true, value: 'item_desc', class: 'accent white--text'},
          { text: 'Quantity Per Case', align: 'left', sortable: false, filterable: true, value: 'qty_per_case', class: 'accent white--text'},
          { text: 'Pack Size', align: 'left', sortable: false, filterable: true, value: 'pack_size', class: 'accent white--text', width: '6%'},
          { text: 'Case Cost', align: 'left', sortable: false, filterable: true, value: 'case_cost', class: 'accent white--text'},
          { text: 'AMAP', align: 'left', sortable: false, filterable: true, value: 'amap', class: 'accent white--text'},
          { text: 'EBA', align: 'left', sortable: false, filterable: true, value: 'early_buy_billback', class: 'accent white--text'},
          { text: 'EDLC BB', align: 'left', sortable: false, filterable: true, value: 'edlc_billback', class: 'accent white--text'},
          { text: 'Ad BB', align: 'left', sortable: false, filterable: true, value: 'ad_billback', class: 'accent white--text'},
          { text: 'TPR BB', align: 'left', sortable: false, filterable: true, value: 'tpr_billback', class: 'accent white--text'},
          { text: 'DSD OI', align: 'left', sortable: false, filterable: true, value: 'dsd_oi_billback', class: 'accent white--text'},
          { text: 'EDLC Scan', align: 'left', sortable: false, filterable: true, value: 'edlc_scan', class: 'accent white--text'},
          { text: 'Ad Scan', align: 'left', sortable: false, filterable: true, value: 'ad_scan', class: 'accent white--text'},
          { text: 'Complex Redemp.', align: 'left', sortable: false, filterable: true, value: 'complex_scan', class: 'accent white--text'},
          { text: 'TPR Scan', align: 'left', sortable: false, filterable: true, value: 'tpr_scan', class: 'accent white--text'},
          { text: 'Net Unit Cost', align: 'left', sortable: false, filterable: true, value: 'unit_cost', class: 'accent white--text'},
          { text: 'NUC - Complex', align: 'left', sortable: false, filterable: true, value: 'complex_net_unit_cost', class: 'accent white--text'},
          { text: 'Gross Profit', align: 'left', sortable: false, filterable: true, value: 'ad_gross_profit_percent', class: 'accent white--text', width: '8%',},
          { text: 'Count', align: 'left', sortable: false, filterable: true, value: 'ad_retail_count', class: 'accent white--text'},
          { text: 'Ad Retail', align: 'left', sortable: false, filterable: true, value: 'ad_retail', class: 'accent white--text'},
          { text: 'Regular Retail', align: 'left', sortable: false, filterable: true, value: 'regular_retail', class: 'accent white--text'},
          { text: 'Mvmt', align: 'left', sortable: false, filterable: true, value: 'last_90_days_shipped_qty', class: 'accent white--text', width: '10%'},
          { sortable: false, filterable: true, value: 'components', class: 'accent white--text', width: '10%'},
          { sortable: false, class: 'accent white--text', width: '12%'}
        ]
      } else if (this.$auth.tenant === 'alliance-retail-group') {
          return [
          { sortable: false, class: 'accent white--text', width: '3%'},
          { text: 'Item Code', align: 'left', sortable: false, filterable: true, value: 'item_id', class: 'accent white--text', width: '10%' },
          { text: 'Feature/Description', align: 'left', sortable: false, filterable: true, value: 'item_desc', class: 'accent white--text' },
          { text: 'Quantity Per Case', align: 'left', sortable: false, filterable: true, value: 'qty_per_case', class: 'accent white--text' },
          { text: 'Pack Size', align: 'left', sortable: false, filterable: true, value: 'pack_size', class: 'accent white--text', width: '6%' },
          { text: 'Case Cost', align: 'left', sortable: false, filterable: true, value: 'case_cost', class: 'accent white--text' },
          { text: 'AMAP', align: 'left', sortable: false, filterable: true, value: 'amap', class: 'accent white--text'},
          { text: 'EBA', align: 'left', sortable: false, filterable: true, value: 'early_buy_billback', class: 'accent white--text'},
          { text: 'EDLC BB', align: 'left', sortable: false, filterable: true, value: 'edlc_billback', class: 'accent white--text'},
          { text: 'TPR BB', align: 'left', sortable: false, filterable: true, value: 'tpr_billback', class: 'accent white--text'},
          { text: 'Ad BB', align: 'left', sortable: false, filterable: true, value: 'ad_billback', class: 'accent white--text'},
          { text: 'EDLC Scan', align: 'left', sortable: false, filterable: true, value: 'edlc_scan', class: 'accent white--text'},
          { text: 'TPR Scan', align: 'left', sortable: false, filterable: true, value: 'tpr_scan', class: 'accent white--text'},
          { text: 'Ad Scan', align: 'left', sortable: false, filterable: true, value: 'ad_scan', class: 'accent white--text'},
          { text: 'ePay EDLC', align: 'left', sortable: false, filterable: true, value: 'epay_edlc_scan', class: 'accent white--text'},
          { text: 'ePay TPR', align: 'left', sortable: false, filterable: true, value: 'epay_tpr_scan', class: 'accent white--text'},
          { text: 'ePay Ad', align: 'left', sortable: false, filterable: true, value: 'epay_ad_scan', class: 'accent white--text'},
          { text: 'EDLC Compete', align: 'left', sortable: false, filterable: true, value: 'edlc_scan_compete', class: 'accent white--text'},
          { text: 'TPR Compete', align: 'left', sortable: false, filterable: true, value: 'tpr_scan_compete', class: 'accent white--text'},
          { text: 'Net Unit Cost', align: 'left', sortable: false, filterable: true, value: 'unit_cost', class: 'accent white--text' },
          { text: 'Gross Profit', align: 'left', sortable: false, filterable: true, value: 'ad_gross_profit_percent', class: 'accent white--text', width: '10%' },
          { text: 'Count', align: 'left', sortable: false, filterable: true, value: 'ad_retail_count', class: 'accent white--text' },
          { text: 'Ad Retail', align: 'left', sortable: false, filterable: true, value: 'ad_retail', class: 'accent white--text' },
          { text: 'Regular Retail', align: 'left', sortable: false, filterable: true, value: 'regular_retail', class: 'accent white--text' },
          { text: 'UPC', align: 'left', sortable: false, filterable: true, value: 'upc', class: 'accent white--text', width: '10%' },
          { text: 'Mvmt', align: 'left', sortable: false, filterable: true, value: 'last_90_days_shipped_qty', class: 'accent white--text', width: '10%' },
          { sortable: false, filterable: true, value: 'components', class: 'accent white--text', width: '10%' },
          { sortable: false, class: 'accent white--text', width: '12%'}
        ]
      } else {
        return [
          { sortable: false, class: 'accent white--text', width: '3%'},
          { text: 'Item Code', align: 'left', sortable: false, filterable: true, value: 'item_id', class: 'accent white--text', width: '10%' },
          { text: 'Feature/Description', align: 'left', sortable: false, filterable: true, value: 'item_desc', class: 'accent white--text' },
          { text: 'Quantity Per Case', align: 'left', sortable: false, filterable: true, value: 'qty_per_case', class: 'accent white--text' },
          { text: 'Pack Size', align: 'left', sortable: false, filterable: true, value: 'pack_size', class: 'accent white--text', width: '6%' },
          { text: 'Case Cost', align: 'left', sortable: false, filterable: true, value: 'case_cost', class: 'accent white--text' },
          { text: 'AMAP', align: 'left', sortable: false, filterable: true, value: 'amap', class: 'accent white--text' },
          { text: 'EBA', align: 'left', sortable: false, filterable: true, value: 'early_buy_billback', class: 'accent white--text' },
          { text: 'EDLC BB', align: 'left', sortable: false, filterable: true, value: 'edlc_billback', class: 'accent white--text' },
          { text: 'Ad BB', align: 'left', sortable: false, filterable: true, value: 'ad_billback', class: 'accent white--text' },
          { text: 'TPR BB', align: 'left', sortable: false, filterable: true, value: 'tpr_billback', class: 'accent white--text' },
          { text: 'EDLC Scan', align: 'left', sortable: false, filterable: true, value: 'edlc_scan', class: 'accent white--text' },
          { text: 'Ad Scan', align: 'left', sortable: false, filterable: true, value: 'ad_scan', class: 'accent white--text' },
          { text: 'TPR Scan', align: 'left', sortable: false, filterable: true, value: 'tpr_scan', class: 'accent white--text' },
          { text: 'Net Unit Cost', align: 'left', sortable: false, filterable: true, value: 'unit_cost', class: 'accent white--text' },
          { text: 'Gross Profit', align: 'left', sortable: false, filterable: true, value: 'ad_gross_profit_percent', class: 'accent white--text', width: '10%' },
          { text: 'Count', align: 'left', sortable: false, filterable: true, value: 'ad_retail_count', class: 'accent white--text' },
          { text: 'Ad Retail', align: 'left', sortable: false, filterable: true, value: 'ad_retail', class: 'accent white--text' },
          { text: 'Regular Retail', align: 'left', sortable: false, filterable: true, value: 'regular_retail', class: 'accent white--text' },
          { text: 'UPC', align: 'left', sortable: false, filterable: true, value: 'upc', class: 'accent white--text', width: '10%' },
          { text: 'Mvmt', align: 'left', sortable: false, filterable: true, value: 'last_90_days_shipped_qty', class: 'accent white--text', width: '10%' },
          { sortable: false, filterable: true, value: 'components', class: 'accent white--text', width: '10%' },
          { sortable: false, class: 'accent white--text', width: '12%'}
        ]
      }
    },
  },

  created () {
    this.tableHeight =  window.innerHeight - 215
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value){
          this.getItems()
          this.searchDocs()
        }
      }
    }
  },

  methods: {
    async download () {
      if (this.selectedAd.status === 'Approved') {
        if (!this.selectedAd.ad_planner_url) {
          await this.searchDocs()
        }
        return location.href = this.selectedAd.ad_planner_url
      }
      this.loadingFile = true
      try {
        const res = await this.$Ads.generateCSV(this.selectedAd.id)
        if (res?.data) {
          location.href = res.data
        }
      } catch (err) {
        console.error(err)
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Error ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loadingFile = false
      }
    },

    async getItems () {
      const limit = this.pageSize
      const offset = (this.page - 1) * this.pageSize
      const party = this.selectedAd.ad_party_id
      const params = {
        party_id: party,
        read_only: 'True',
        limit, 
        offset
      }
      let items = []
      this.loading = true
      try {
        const res = await this.$AdRetails.getItems(this.selectedAd.id, params)
        if (res?.data?.length > 0) {
          items = res.data.map(item => {
            item.pagePos = `${item.page_name} : ${item.position_name}`
            return item
          })
        }
        this.items = items
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Items due to ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loading = false
      }
    },

    onResize () {
      this.tableHeight =  window.innerHeight - 240
    },

    async searchDocs() {
      if (this.selectedAd.status == 'Approved') {
        this.loadingFile = true
        const tags = { ad_id: this.selectedAd.id }
        const params1 = {
          root_key: 'ipro_portal',
          party_ids: this.selectedAd.ad_party_id,
          tags: JSON.stringify(tags)
        }
        try {
          const res = await this.$Documents.searchDocs(params1)
          if (res?.data?.length > 0) {
            const doc = res.data.find(r => r.tags?.ad_id === this.selectedAd.id)
            if (doc) {
              const fileRes = await this.$Documents.getFile(doc.id)
              if (fileRes?.data) {
                this.selectedAd.ad_planner_url = fileRes.data.url
              }
            }
          }
        } catch (err) {
          console.error(err)
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Error ${err?.response?.data?.message || err.message}` })
        } finally {
          this.loadingFile = false
        }
      }
    },

  previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getItems()
    },

  next () {
      this.page = this.page + 1
      this.getItems()
    },
  }
}
</script>
