import { render, staticRenderFns } from "./ad-planner-read-only.vue?vue&type=template&id=1663acb0"
import script from "./ad-planner-read-only.vue?vue&type=script&lang=js"
export * from "./ad-planner-read-only.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports